<template lang="pug">
  div.action_container
    vue-headful(title="Ownmesh actions")
    div.pb-4
    div.action_type Sync
    b-table.action_list#pager-aria(small hover :items="sync_map" :fields="sync_fields")
      template(v-slot:cell(triggers)="action")
        div(v-bind:style="{'background-color': action.item.color}")
          template(v-for="datatrigger in get_datatriggers(action.item)")
            div.data_name.trigger Data: {{ get_data(datatrigger).shortname }}
          template(v-for="schedulertrigger in get_schedulertriggers(action.item)")
            div.scheduler.trigger(@click="show_scheduler(schedulertrigger.id)")
              | Scheduler: {{ schedulertrigger.text }}
      template(v-slot:cell(inputdata)="action")
        div(v-bind:style="{'background-color':action.item.color}")
          | {{ get_data(action.item.inputdata).shortname }}
      template(v-slot:cell(outputdata)="action")
        div.right(v-bind:style="{'background-color':action.item.color}")
          | {{ get_data(action.item.outputdata).shortname }}
    div.pb-4
    div.action_type Setvalue
    b-table.action_list#pager-aria(small hover :items="setvalue_map" :fields="setvalue_fields")
      template(v-slot:cell(triggers)="action")
        div(v-bind:style="{'background-color': action.item.color}")
          template(v-for="datatrigger in get_datatriggers(action.item)")
            div.data_name.trigger Data: {{ get_data(datatrigger).shortname }}
          template(v-for="schedulertrigger in get_schedulertriggers(action.item)")
            div.scheduler.trigger(@click="show_scheduler(schedulertrigger.id)")
              | Scheduler: {{ schedulertrigger.text }}
      template(v-slot:cell(value)="action")
        div(v-bind:style="{'background-color':action.item.color}")
          | {{ get_setvalue(action.item) }}
      template(v-slot:cell(outputdata)="action")
        div.right(v-bind:style="{'background-color':action.item.color}")
          | {{ get_data(action.item.outputdata).shortname }}
    div.pb-4
    div.action_type Compare
    b-table.action_list#pager-aria(small hover :items="compare_map" :fields="compare_fields")
      template(v-slot:cell(triggers)="action")
        div(v-bind:style="{'background-color': action.item.color}")
          template(v-for="datatrigger in get_datatriggers(action.item)")
            div.data_name.trigger Data: {{ get_data(datatrigger).shortname }}
          template(v-for="schedulertrigger in get_schedulertriggers(action.item)")
            div.scheduler.trigger(@click="show_scheduler(schedulertrigger.id)")
              | Scheduler: {{ schedulertrigger.text }}
      template(v-slot:cell(inputdata)="action")
        div(v-bind:style="{'background-color':action.item.color}")
          | {{ get_data(action.item.inputdata).shortname }}
      template(v-slot:cell(setpointdata)="action")
        div(v-bind:style="{'background-color':action.item.color}")
          | {{ get_data(action.item.setpointdata).shortname }}
      template(v-slot:cell(outputdata)="action")
        div.right(v-bind:style="{'background-color':action.item.color}")
          | {{ get_data(action.item.outputdata).shortname }}
</template>

<script>
import { mapGetters } from 'vuex'
import bus from '@/services/bus'
import Icon from 'vue-awesome/components/Icon'
import 'vue-awesome/icons/plus'
// import CONF from '@/conf'
import { get_element_by_id } from '@/utils'

const empty_data = {name: null, shortname: null}

export default {
  name: 'Action_landing',
  components: {
    'icon': Icon
  },

  data () {
    return {
      loading: true,
      compare_map: [],
      sync_map: [],
      setvalue_map: [],
      sync_fields: [
        { key: 'triggers', label: 'Triggers', sortable: true },
        { key: 'inputdata', label: 'Input data', sortable: true },
        { key: 'outputdata', label: 'Output data', sortable: true }
      ],
      setvalue_fields: [
        { key: 'triggers', label: 'Triggers', sortable: true },
        { key: 'value', label: 'Value', sortable: true },
        { key: 'outputdata', label: 'Output data', sortable: true }
      ],
      compare_fields: [
        { key: 'triggers',  label: 'Triggers', sortable: true },
        { key: 'inputdata', label: 'Input data', sortable: true },
        { key: 'setpointdata', label: 'Setpoint data', sortable: true },
        { key: 'outputdata', label: 'Output data', sortable: true }
      ]
    }
  },

  computed: {
    ...mapGetters({
      socket_state: 'get_socket_state',
      action_map: 'get_action_map',
      data_map: 'get_data_map',
      datatrigger_map: 'get_datatrigger_map',
      scheduler_map: 'get_scheduler_map',
      scheduler_event_map: 'get_scheduler_event_map',
      action_landing_state: 'get_action_landing_state',
      data_landing_state: 'get_data_landing_state'
    })
  },

  mounted () {
    if (this.action_landing_state) {
      this.loading = false
      this.set_action_maps()
      bus.$emit('route/ready', null)
    }
    bus.$on('socket/state', (response) => {
      if (!response || this.action_landing_state) return
      let already_fetched = ''
      if (this.data_landing_state) { already_fetched += 'data,' }
      this.$socket.send('action_landing', JSON.stringify({already_fetched: already_fetched}))
    })
    bus.$on('update/action', (action) => {
      this.push_action(action)
    })
    bus.$on('state/action_landing', (state) => {
      if (!state) return
      // this.set_action_maps()
      // this.$toaster['info']('Ready !')
      bus.$emit('route/ready', null)
    })
    this.$socket.connect()
  },

  beforeDestroy () {
    bus.$off('socket/state')
    bus.$off('update/action')
    bus.$off('state/action_landing')
  },

  methods: {
    add_action: function (type) {
      console.log(`Add ${type} action`)
    },
    get_data: function (data_pk) {
      if (!data_pk) return empty_data
      return get_element_by_id(this.data_map, data_pk) || empty_data
    },
    get_datatriggers: function (action) {
      if (!this.datatrigger_map.length) return
      let datatriggers = []
      for (let trigger of this.datatrigger_map) {
        if (trigger.action !== action.id) continue
        // if (trigger.data === action.inputdata) continue
        // if (trigger.data === action.setpointdata) continue
        datatriggers.push(trigger.data)
      }
      return datatriggers
    },
    get_schedulertriggers: function (action) {
      if (!this.scheduler_map.length) return
      let schedulertriggers = []
      for (let scheduler of this.scheduler_map) {
        if (scheduler.default_action !== action.id) continue
        if (get_element_by_id(schedulertriggers, scheduler.id)) continue
        schedulertriggers.push({id: scheduler.id, text: 'default action'})
      }
      if (!this.scheduler_event_map.length) return schedulertriggers
      for (let event_map of this.scheduler_event_map) {
        for (let event of event_map.entries) {
          if (event.action !== action.id) continue
          if (get_element_by_id(schedulertriggers, event.scheduler)) continue
          schedulertriggers.push({id: event.scheduler, text: 'event action'})
        }
      }
      return schedulertriggers
    },

    show_scheduler: function (id) {
      this.$router.push(`/schedulers/${id}`)
    },

    get_setvalue: function (action) {
      if (!action.run_args) return
      let run_args = JSON.parse(action.run_args)
      return run_args.value
    },
    push_action: function (action) {
      let action_map_name = `${action.action_type}_map`
      let old_action = get_element_by_id(this[action_map_name], action.id)
      if (old_action) old_action = action
      else this[action_map_name].push(action)
    },
    set_action_maps: function () {
      this.compare_map = []; this.sync_map = []; this.setvalue_map = []
      for (let action of this.action_map) {
        this.push_action(action)
      }
    }
  },

}
</script>

<style lang="scss">
@import '../assets/colors';
@import '../assets/constants';
@import '../assets/mixins';

@media only screen and (min-width: 0) {
  .action_container {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) {
  .control_container {
    width: 70%;
  }
}

@media only screen and (min-width: 768px) {
  .control_container {
    width: 75%;
  }
}

.action_container {
  color: $black;
  padding: 0 .2rem;
  // margin-left: .2rem;
  table {
    &.action_list {
      color: $white;
      font-size: 120%;

      thead {
        color: $black;
        tr {
          th {
            background-color: lighten($login_bgcolor, 30);
            border: 0;
            padding-bottom: .6rem;
            padding-top: 1rem;
            &:first-child {
              border-top-left-radius: 2px;
            }
            &:last-child {
              border-top-right-radius: 2px;
              text-align: right;
            }
          }
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid transparent;
          // min-height: 5rem;
          height: 6rem;

          td {
            border-top: 0;
            height: inherit;
            min-height: 5rem;
            padding: 0;

            > div {
              height: 100%;
              padding: 1rem .5rem;
            }

            > .right {
              text-align: right;
            }
          }
        }
      }
    }
  }
  .action_type {
    background-color: lighten($login_bgcolor, 30);
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
    display: inline-block;
    font-size: 1.75rem;
    padding: .2rem .4rem;
    padding-bottom: 0;
  }
  .data_name,
  .scheduler,
  .run_args {
    cursor: pointer;
    margin-left: 1rem;
  }
}

</style>
